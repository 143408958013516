import classNames from 'classnames'
import React from 'react'
import Image from 'components/Image'
import Link from 'components/Link'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Post.module.scss'

type Props = {
  image: string
  title: string
  to: string
} & Component

export default function Post({ className, image, title, to }: Props) {
  return (
    <Link to={to} className={classNames('Post', styles.this, className)}>
      <Text element="figure" className={styles.figure}>
        <Image src={image} className={styles.image} />
        <Text element="figcaption" className={styles.caption}>
          <Text element="h3" weight="medium" className={styles.title}>
            {title}
          </Text>
        </Text>
      </Text>
    </Link>
  )
}
