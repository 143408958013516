import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import { Component } from 'constants/types'
import styles from './Copy.module.scss'

type Props = {
  children: React.ReactNode
  columns?: number
} & Component

export default function Copy({ children, className, columns, ...props }: Props) {
  const style = columns ? { columnCount: columns } : {}

  return (
    <Text
      {...props}
      element="div"
      style={style}
      className={classNames('Copy', 'BlogCopy', styles.this, className)}>
      {children}
    </Text>
  )
}
